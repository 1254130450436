import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Slider from "../components/home/SliderComp2";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import useLocalStorage from "../components/useLocalStorage";
import TopBar from "../components/Layout/TopBar";
// import SEO from "../components/seo"
import SEO from "../components/seo";
import Seo from "../components/seo";
import EntryFormButton from "../components/EntryFormButton";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import hero from '../images/comp/prize-img.jpg';
import hero2 from '../images/comp/placeholder.png';
import { Field, Form, Formik, ErrorMessage } from "formik";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import { FaSpinner } from "react-icons/fa";

// import "../scss/main.scss";

export default function Story2024() {

  return (
    <>{ /* <SEO title="Welcome" keywords={[`great`, `british`, `dogs`]} /> */ }
    <SEO
      title="greatbritishdogs.co.uk | DOTY Voting 2024"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <div css={css`
                background-color: #004030 !important;
                min-height: calc(100vh - 84px) !important;
                `}>
            <div css={css`
                width: 100% !important; max-width: 1060px !important; padding-top: 50px !important; padding-right: 1rem !important; padding-left: 1rem !important; margin: 0 auto !important;`}>
            <h1 css={css`
                display: none !important;
                `}>
              Cast your vote to win
            </h1>
            <h2 css={css`
                display: none !important;
                `}>Want to help Winalot<sup>®</sup> crown Dog of the Year 2024? Vote for your favourite now for a chance to win a £100 Amazon voucher</h2>
            <div class="flex">

            <div css={css`
                display: table;
                width: 100%;
            
                @media screen and (max-width: 768px) {
                    display: block;
                }
                `}>

                <div css={css`
                display: table-cell;
                vertical-align: middle;
                width: 55%;
                left: 0;
            
                @media screen and (max-width: 768px) {
                    display: block;
                    width: 100%;
                    left: auto;
                    padding-right: 0%;
                }
                `}>

                    <img src="https://www.greatbritishdogs.co.uk/winalot-2024-comp/img/comp-dog-img.png" alt="Share your dog stories to win graphic" width="792" height="753" style={{display: 'block', width: '100%', height: 'auto'}}></img>

                </div>

                <div css={css`
                display: table-cell;
                vertical-align: middle;
                right: 0;
                width: 45%;
            
                @media screen and (max-width: 768px) {
                    display: block;
                    width: 100%;
                    right: auto;
                    padding-left: 0%;
                    margin-top: -90px;
                }
                `}>

                    <img src="https://www.greatbritishdogs.co.uk/doty-2024-form/img/comp-vote-graphic.png" alt="Cast your vote to win graphic" width="618" height="218" style={{display: 'block', width: '100%', height: 'auto'}}></img>
                    <h2 css={css`
                color: #fff;
                font-family: 'Source Sans 3', sans-serif;
                font-weight: 500;
                font-size: 1.4em;
                line-height: 1.3em;
                margin: 20px auto 30px auto;
                text-align: center;
                `}>Want to help Winalot<sup>®</sup> crown Dog of the Year 2024? Vote for your favourite now for a chance to win a £100 Amazon voucher</h2>

{/* <div className="enter-now-button"
  onClick={(e) => {
    e.preventDefault();
    window.location.href = "https://www.greatbritishdogs.co.uk/doty-voting-2024-form/";
  }} css={css`text-align: center;
    font-weight: 600;
    font-size: 1em;
    margin-bottom: -20px;
    color: #fff;
    width: 130px;
    padding: 12px 0px;
    border-radius: 15px;
    margin: 0 auto;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #AE456C;

    @media screen and (max-width: 600px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 414px) {
        max-width: 100%;
    }`}>Vote now</div>*/}

<div className="enter-now-button" css={css`text-align: center;
    font-weight: 600;
    font-size: 1em;
    margin-bottom: -20px;
    color: #fff;
    width: 130px;
    padding: 12px 0px;
    border-radius: 15px;
    margin: 0 auto;
    cursor: default;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #AE456C;
    opacity: 0.5;

    @media screen and (max-width: 600px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 414px) {
        max-width: 100%;
    }`}>Vote now</div>

<p css={css`
                color: #fff;
                font-family: 'Source Sans 3', sans-serif;
                font-weight: 600;
                font-size: 1.2em;
                line-height: 1.3em;
                margin: 30px auto 0 auto;
                text-align: center;
                `}>Voting is now closed</p>

<p css={css`
                color: #fff;
                font-family: 'Source Sans 3', sans-serif;
                font-weight: 400;
                font-size: 0.9em;
                line-height: 1.3em;
                margin: 20px auto 0 auto;
                text-align: center;
                `}>Find out more about the Dog of the Year prize <a href="https://www.greatbritishdogs.co.uk/terms-and-conditions/#form" target="_blank" className="learn-more-text-link" style={{textDecoration: 'underline', fontWeight: '500'}}>here</a></p>


            </div>
            <br /><br />
          </div>
      </div>
      </div>
      </div>
      </>
    );
}